import * as i18nIsoCountries from "i18n-iso-countries";
import enIsoNames from "i18n-iso-countries/langs/en.json";
import moment from "moment";

// THIS FILE SHOULD CONTAIN EVERYTHING THAT HELPS WITH FORMATTING OR PRESENTATION LEVEL LOGIC

// TODO: move all the formatting helpers to this file
export const formatNumberWithSeperators = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatLocalisedDate = (utcDate, formatString) => {
  const momentUtcDate = moment.utc(utcDate);
  if (momentUtcDate.isValid()) {
    const localDate = moment(momentUtcDate).local();
    return localDate.format(formatString);
  }
  return "N/A";
};

export const toTitleCase = (title) =>
  title.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
  );

export const pascalCaseToTitleCase = (text) => {
  const titleCase = text.replace(/([A-Z])/g, " $1").trim();
  return titleCase.charAt(0).toUpperCase() + titleCase.slice(1).toLowerCase();
};

export const sortCompare = (a, b, key) => {
  // Use toUpperCase() to ignore character casing
  const value1 = a[key].toUpperCase();
  const value2 = b[key].toUpperCase();

  let comparison = 0;
  if (value1 > value2) {
    comparison = 1;
  } else if (value1 < value2) {
    comparison = -1;
  }
  return comparison;
};

export const getCurrentMonth = () => {
  const d = moment();
  d.month(); // 1
  return d.format("MMMM");
};

export const copy = (id, msg) => {
  const key = document.getElementById(id);
  if (!key) return;
  const range = document.createRange();
  range.selectNode(key);
  const selection = window.getSelection();
  // this may look weird, but selections have an array of ranges, and which is
  // chosen is an implementation that may vary by browser.
  selection.removeAllRanges();
  selection.addRange(range);
  try {
    const successful = document.execCommand("copy");
    if (successful) {
      return { message: msg };
    }
  } catch (err) {
    return { error: "Unable to copy text" };
  }
};

export const getCountry = (abbreviation) =>
  ({
    NG: "Nigeria",
    KE: "Kenya",
    GH: "Ghana",
    TZ: "Tanzania",
    ZA: "South Africa",
    UG: "Uganda",
    RW: "Rwanda",
    ZM: "Zambia",
  })[abbreviation];

export const getCurrencySymbol = (currency) =>
  ({
    USD: "$",
    NGN: "₦",
    KES: "K",
    KSH: "K",
    ZAR: "R",
  })[currency];

/**
 * Given a supported currency return the country iso code
 * @param {string} currency - ISO4217 alpha code representing currency.
 * @returns {string} ISO3166 alpha code representing country.
 */
export const getCurrencyCountry = (currency) =>
  ({
    USD: "US",
    NGN: "NG",
    KES: "KE",
    KSH: "KE",
    ZAR: "ZA",
  })[currency];

export const convertCurrency = (that, amount) => {
  const { exchangeRates } = that.state;
  if (!exchangeRates) return 0.0;
  let converted =
    amount * parseFloat(exchangeRates[that.state.currency]).toFixed(2);
  if (that.state.currency !== "USD") {
    converted = Math.ceil(converted);
  }
  return converted.toFixed(2);
};

export const isBase64 = (str) => {
  try {
    // simple check to see whether it can be decoded or not (i.e. valid base64)
    return str.includes("data:image") ||
      str.includes("base64") ||
      window.atob(str)
      ? str
      : false;
  } catch (err) {
    return false;
  }
};

export const generateRandomID = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const truncate = (str, n, useWordBoundary = false) => {
  if (str === null) return "";
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString;
};

export const convertStatusToColor = (status) => {
  const color = {
    new: "#2D9CDB",
    open: "#E66363",
    pending: "#EDA845",
    solved: "#219653",
    closed: "#4F4F4F",
  };

  if (!status) return color.closed;
  return color[status.toLowerCase()];
};

/**
 * Check if the param has a value.
 * @param {any} value - value to be checked.
 * @returns {boolean} true if value is undefined, null or empty string.
 */
export function isEmpty(value) {
  return value === undefined || value === null || value === "";
}

/**
 * Formats a number into a currency string using the browsers built
 * in localization helpers. If a currencyCode is not provided, value in
 * $$$,$$$.$$ format is returned.
 * @param {number} value - number to be formatted.
 * @param {string|undefined} currencyCode - 3 letter ISO-3166 Currency code.
 * @returns {string} currency string.
 */
export const formatCurrency = (value, currencyCode) => {
  const amount = parseFloat(value);
  if (Number.isNaN(amount)) {
    return "";
  }

  if (typeof currencyCode !== "string") {
    const splitVal = (+value).toFixed(2).split(".");
    const dollars = splitVal[0]
      .replace(/(?=(\d{3})+(?!\d))/g, ",")
      .replace(/^,/g, "");
    const cents = splitVal[1] ?? "00";
    return `${dollars}.${cents}`;
  }

  // NOTE: The first two letters of all ISO-3166
  // currency codes map to alpha 2 country codes.
  // returns a string like `$ 123,456.78` or `R 123 456,78`
  const countryCode = currencyCode.substring(0, 2);
  try {
    value = new Intl.NumberFormat(`en-${countryCode}`, {
      currency: currencyCode,
      style: "currency",
    }).format(value);
  } catch (error) {
    console.error(error);
  }
  // returns original value as a fallback.
  return value;
};

i18nIsoCountries.registerLocale(enIsoNames);

export const ISOCountryCodeToFullNameMap = i18nIsoCountries.getNames("en");

/**
 * Concatenate several css class names together
 * Strips out falsy values
 * @param   {string[]}  classNames  the classes to concat
 * @returns  {string}              the concat result
 */
export function css(...classNames) {
  return classNames?.filter(Boolean)?.join(" ");
}

/**
 * Removes - and _ from a string
 * @param {string} value the value containing - and _
 * @param {string} separator what to use to separate the result
 * @returns {string} reformated string
 */
export function normalizeString(value, separator = " ") {
  return value?.replaceAll(/[_-]/g, separator);
}
