import { connect } from "react-redux";
import { submitReview, dispatchMessage } from "actions/actions";
import SpoofReview from "components/reusable/SpoofReview";

const mapStateToProps = (state) => ({
  reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) => ({
  submitReview: (reviewInfo) => submitReview(reviewInfo, dispatch),
  dispatchMessage: (message) => dispatchMessage(message, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpoofReview);
