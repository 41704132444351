// Action value icons
import android from "resources/img/icons/android.svg";
import apple from "resources/img/icons/apple.svg";
import brackets from "resources/img/icons/brackets.svg";
import desktop from "resources/img/icons/desktop.svg";
import dot from "resources/img/icons/dot--grey.svg";
import info from "resources/img/icons/info--orange.svg";
import infoRed from "resources/img/icons/info--red.svg";
import tick from "resources/img/icons/tick--green.svg";
import cross from "resources/img/icons/x--red.svg";

// source logos

// ID image logos
import GH_DVLA from "resources/img/id_provider_logos/GH/GH_DVLA.svg";
import GH_EC from "resources/img/id_provider_logos/GH/GH_EC.svg";
import GH_MFARI from "resources/img/id_provider_logos/GH/GH_MFARI.svg";
import GH_SSNIT from "resources/img/id_provider_logos/GH/GH_SSNIT.svg";
import KE_BRS from "resources/img/id_provider_logos/KE/KE_BRS.svg";
import KE_ECITIZEN from "resources/img/id_provider_logos/KE/KE_ECITIZEN.svg";
import KE_IPRS from "resources/img/id_provider_logos/KE/KE_IPRS.svg";
import NG_CAC from "resources/img/id_provider_logos/NG/NG_CAC.svg";
import NG_FIRS from "resources/img/id_provider_logos/NG/NG_FIRS.svg";
import NG_FRSC from "resources/img/id_provider_logos/NG/NG_FRSC.svg";
import NG_INEC from "resources/img/id_provider_logos/NG/NG_INEC.svg";
import NG_NIBSS from "resources/img/id_provider_logos/NG/NG_NIBSS.svg";
import NG_NIMC from "resources/img/id_provider_logos/NG/NG_NIMC.svg";
import NG_NIS from "resources/img/id_provider_logos/NG/NG_NIS.svg";
import NG_NUBAN from "resources/img/id_provider_logos/NG/NG_NUBAN.svg";
import NG_PHONE_NUMBER from "resources/img/id_provider_logos/NG/NG_PHONE_NUMBER.svg";

import TZ_NIDA from "resources/img/id_provider_logos/TZ/TZ_NIDA.png";
import TZ_TRA from "resources/img/id_provider_logos/TZ/TZ_TRA.png";

import UG_LABOREMUS from "resources/img/id_provider_logos/UG/UG_LABOREMUS.svg";
import UG_NIRA from "resources/img/id_provider_logos/UG/UG_NIRA.svg";
import ZA_CBA from "resources/img/id_provider_logos/ZA/ZA_CBA.svg";
import ZA_BUSINESS_REGISTRATION from "resources/img/id_provider_logos/ZA/ZA_CIPC.svg";
import ZA_DHA from "resources/img/id_provider_logos/ZA/ZA_DHA.svg";
import ZM_BOZ from "resources/img/id_provider_logos/ZM/ZM_BANK.svg";
import ZM_ZRA from "resources/img/id_provider_logos/ZM/ZM_TPIN.svg";
import { JobTypes } from "./constants";

// at a glance all the actions logic should be in this file.
export const ACTION_LABELS = {
  Register_Selfie: "Register a Selfie",
  Liveness_Check: "Perform Proof of Life Check",
  Human_Review_Liveness_Check: "Human Review for Liveness Check",
  Update_Registered_Selfie_On_File: "Update User's Registered Selfie on File",
  Human_Review_Update_Selfie: "Human Review for Update User Selfie",
  Selfie_To_ID_Authority_Compare: "Compare Selfie to Govt ID Authority",
  Selfie_To_ID_Card_Compare: "Compare Selfie to Photo of Physical ID Card",
  Verify_ID_Number: "Verify ID Number",
  Verify_Document: "Verify Document",
  Return_Personal_Info: "Return Personal Info",
  Selfie_To_Registered_Selfie_Compare:
    "Compare Incoming Selfie to Registered Selfie",
  Human_Review_Compare: "Human Review for Selfie to Selfie Compare",
  Deduplication: "Deduplication Performed",
  Human_Review_Deduplication: "Human Review for Duplicate Photos",
};

export const actionIconMapping = (str) => {
  switch (str) {
    case "tick":
      return tick;
    case "cross":
      return cross;
    case "info":
      return info;
    case "infoRed":
      return infoRed;
    case "dot":
      return dot;
    default:
      return "";
  }
};

export const getRelevantActionValueIcons = (actionValObj, value) => {
  for (let i = 0; i < Object.keys(actionValObj).length; i += 1) {
    const key = Object.keys(actionValObj)[i];
    if (actionValObj[key].action_values.includes(value)) {
      return actionIconMapping(actionValObj[key].action_icon);
    }
  }
};

export const getRelevantDisplayValue = (actionValObj, value, title) => {
  // show passed or failed for machine and human analysis
  if (
    title === "Machine Analysis" &&
    actionValObj.neutral.action_values.includes(value)
  ) {
    return "Sent to Human";
  }
  if (["Machine Analysis", "Human Analysis"].includes(title)) {
    if (actionValObj.success.action_values.includes(value)) {
      return "Passed";
    }
    if (
      actionValObj.error.action_values.includes(value) ||
      actionValObj.info_error.action_values.includes(value)
    ) {
      return "Failed";
    }
  }

  // for Result values, we show yes and no
  if (title === "Results") {
    if (actionValObj.neutral.action_values.includes(value)) {
      return "Under Review";
    }

    if (actionValObj.success.action_values.includes(value)) {
      return "Yes";
    }
    if (
      actionValObj.error.action_values.includes(value) ||
      actionValObj.info_error.action_values.includes(value)
    ) {
      return "No";
    }
  }

  return value;
};

export const getActionLabel = (
  actionLabels,
  key,
  jobType,
  isJobType1IdCardImage,
) => {
  if (
    key === "Human_Review_Compare" &&
    ((parseInt(jobType, 10) === JobTypes.BIOMETRIC_KYC &&
      isJobType1IdCardImage) ||
      parseInt(jobType, 10) === JobTypes.DOCUMENT_VERIFICATION)
  ) {
    return "Selfie Comparison to ID Document Photo";
  }
  if (
    key === "Human_Review_Compare" &&
    parseInt(jobType, 10) === JobTypes.SMART_SELFIE_AUTHENTICATION
  ) {
    return "Incoming Selfie Comparison to Registered Selfie";
  }
  return actionLabels[key];
};

export const getRelevantActions = (actionJobTypeMap, jobType) =>
  actionJobTypeMap[jobType] || [];

export const COUNTRY_CODES = {
  nigeria: "NG",
  kenya: "KE",
  ghana: "GH",
  tanzania: "TZ",
  "south africa": "ZA",
  uganda: "UG",
  zambia: "ZM",
};

// these are the provider logos for display on the results/kyc pages
// the web app sample cards need to be updated separately in web_app_helpers.js
export const ID_IMAGE_PROVIDERS = {
  NG_BVN: NG_NIBSS,
  NG_NIN: NG_NIMC,
  NG_NIN_V2: NG_NIMC,
  NG_NIN_SLIP: NG_NIMC,
  NG_PHONE_NUMBER,
  NG_PASSPORT: NG_NIS,
  NG_DRIVERS_LICENSE: NG_FRSC,
  NG_VOTER_ID: NG_INEC,
  NG_NATIONAL_ID: NG_NIMC,
  NG_TIN: NG_FIRS,
  NG_BASIC_BUSINESS_REGISTRATION: NG_CAC,
  NG_BUSINESS_REGISTRATION: NG_CAC,
  NG_TAX_INFORMATION: NG_FIRS,
  NG_BANK_ACCOUNT: NG_NUBAN,

  KE_ALIEN_CARD: KE_IPRS,
  KE_DRIVERS_LICENSE: KE_BRS,
  KE_KRA_PIN: KE_BRS,
  KE_NATIONAL_ID: KE_IPRS,
  KE_NATIONAL_ID_NO_PHOTO: KE_ECITIZEN,
  KE_PASSPORT: KE_IPRS,
  KE_PHONE_NUMBER: NG_PHONE_NUMBER,
  KE_BUSINESS_REGISTRATION: KE_BRS,

  GH_DRIVERS_LICENSE: GH_DVLA,
  GH_PASSPORT: GH_MFARI,
  GH_SSNIT,
  GH_VOTER_ID: GH_EC,
  GH_NEW_VOTER_ID: GH_EC,

  TZ_NIDA,
  TZ_DRIVERS_LICENSE: TZ_TRA,
  TZ_PHONE_NUMBER: NG_PHONE_NUMBER,

  ZA_NATIONAL_ID: ZA_DHA,
  ZA_NATIONAL_ID_NO_PHOTO: ZA_DHA,
  ZA_PHONE_NUMBER: ZA_CBA,
  ZA_BUSINESS_REGISTRATION,

  UG_NATIONAL_ID_NO_PHOTO: {
    LABOREMUS: UG_LABOREMUS,
    NIRA: UG_NIRA,
  },

  UG_PHONE_NUMBER: NG_PHONE_NUMBER,

  ZM_BANK_ACCOUNT: ZM_BOZ,
  ZM_TPIN: ZM_ZRA,
};

export const ID_PROVIDER_NAMES = {
  NG_BASIC_BUSINESS_REGISTRATION: "Corporate Affairs Commission",
  NG_BUSINESS_REGISTRATION: "Corporate Affairs Commission",
  NG_TAX_INFORMATION: "Federal Inland Revenue Service",
  ZA_BUSINESS_REGISTRATION: "Companies and Intellectual Property Commission",
  KE_BUSINESS_REGISTRATION: "Business Registration Service",
};

export const unifyIDAPICountry = (country) => {
  if (country && country.length > 2) {
    return COUNTRY_CODES[country.toLowerCase()];
  }
  return country;
};

export const getProviderImage = (country, idType, provider) => {
  country = unifyIDAPICountry(country);
  if (country && idType) {
    const key = `${country.toUpperCase()}_${idType.toUpperCase()}`;
    const objOrIcon = ID_IMAGE_PROVIDERS[key];

    return objOrIcon?.[provider?.toUpperCase()] ?? objOrIcon;
  }
};

export const getProviderImageName = (country, IDType) => {
  country = unifyIDAPICountry(country);
  if (country && IDType) {
    return ID_PROVIDER_NAMES[
      `${country.toUpperCase()}_${IDType.toUpperCase()}`
    ];
  }
};

/**
 * Determines the KYC job type ("Enhanced KYC" or "Basic KYC") for a given ID verification provider,
 * specifically for Uganda's "NATIONAL_ID_NO_PHOTO". It categorizes providers by their service level
 * for this ID type. Only 'LABOREMUS' (for Enhanced KYC) and 'NIRA' (for Basic KYC) are currently supported.
 * @param {string} provider - The provider's name, case-insensitive.
 * @returns {string|null} - The job type as a string, or null if the provider is not recognized.
 */
export const getProviderImageJobType = (provider) => {
  const ID_PROVIDER_JOB_TYPES = {
    LABOREMUS: "Enhanced KYC",
    NIRA: "Basic KYC",
  };

  return provider ? ID_PROVIDER_JOB_TYPES[provider.toUpperCase()] : null;
};

export const getSourceIcon = (source) => {
  if (typeof source !== "string") {
    return;
  }
  const s = source.toLowerCase();
  if (["hosted_web", "web api", "webapi"].some((item) => s.includes(item))) {
    return desktop;
  }
  if (
    ["android", "huawei", "samsung", "techno"].some((item) => s.includes(item))
  ) {
    return android;
  }
  if (["apple", "ios", "iphone"].some((item) => s.includes(item))) {
    return apple;
  }
  if (
    [
      "id api",
      "id verification",
      "idapi",
      "internal_verification_api",
      "java",
      "javascript",
      "php",
      "rest_api",
      "rest-api",
      "ruby",
    ].some((item) => s.includes(item))
  ) {
    return brackets;
  }

  return null;
};

export const getImageLabels = (jobType, isJobType1IdCardImage) => {
  if (isJobType1IdCardImage) {
    return { source: "Selfie", target: "ID Document Photo (Front)" };
  }

  const jobTypeMapping = {
    1: { source: "Selfie", target: "Photo from ID Authority" },
    2: { source: "Registered Selfie", target: "Incoming Selfie" },
    4: { source: "Incoming Selfie", target: "" },
    5: { source: "", target: "Photo from ID Authority" },
    6: {
      source: "Incoming Selfie",
      target: "ID Document Photo (Front)",
      backID: "ID Document Photo (Back)",
    },
    11: {
      source: "Incoming Selfie",
      target: "ID Document Photo (Front)",
      backID: "ID Document Photo (Back)",
    },
  };

  return jobTypeMapping[jobType] || {};
};

export const getPhotoWithLabels = (images) => {
  const labels = {
    0: "Incoming Selfie",
    1: "ID Document Photo (Front)",
    5: "ID Document Photo (Back)",
    9: "Photo from ID Authority",
    11: "ID Authority Document",
    12: "Portrait",
  };
  const photos = [];
  images.forEach((image) => {
    // We currently don't display images such as liveness images, upscaled document images,
    // or ID authority document images. If we choose to do so, add them to the mapping above.
    if (!labels[image.image_type]) {
      return;
    }
    photos.push({
      image: image.link,
      // If using a registered selfie on file from an enrollee the job_id of the current job
      // would not be in the path to the result folder. If this job is the one that registered
      // image the job_id would be in the results folder path and the image would be labelled
      // incoming selfie.
      label: image.enrolled_image
        ? "Registered Selfie"
        : labels[image.image_type],
    });
  });
  return photos;
};

export const formatPercentage = (str, decimals) =>
  parseFloat(parseFloat(str).toFixed(decimals));

/**
 * Set corresponding machine analysis to 'sent to human' if
 * the field was set as a result of a human reviewer
 * @param   {object}  actions - Actions in the job results object
 * @returns  {object} Updated actions
 */
export const machineAnalysisUpdate = (actions) => {
  const relevantActions = { ...actions };

  // Liveness_Check updated to 'sent to human'
  // if Human_Review_Liveness_Check contain a valid value
  Object.keys(actions).forEach((actionName) => {
    const actionValue = actions[`Human_Review_${actionName}`];
    if (actionValue !== "Not Applicable" && actionValue) {
      relevantActions[actionName] = "Sent to Human";
    }
  });

  return relevantActions;
};

/**
 * Gets actions relevant to the jobtype, removes empty and Not Applicable values
 * @param {object} actionJobTypeMap A sorted list of action keys
 * @param {object} actions Actions with values found in the results object
 * @param {number} jobType Jobtype
 * @returns {object} Actions - Updated actions without empty values
 */
export const relevantActionsWithValues = (
  actionJobTypeMap,
  actions,
  jobType,
) => {
  const completed = {};

  getRelevantActions(actionJobTypeMap, jobType).forEach((action) => {
    // show only actions with values
    if (actions[action] === "Not Applicable" || actions[action] === undefined) {
      return;
    }
    if (
      actions.Liveness_Images_Count === 0 &&
      ["Human_Review_Liveness_Check", "Liveness_Check"].includes(action)
    ) {
      return;
    }

    completed[action] = actions[action];
  });

  return completed;
};

export const buildKYCActions = (actions, jobs) => {
  const completed = {};
  const notApplicable = {};
  // the way the query pulls the actions from the db leaves them as stringified json
  const results = jobs
    .map((job) =>
      typeof job.result === "string" ? JSON.parse(job.result) : job.result,
    )
    .reverse();
  actions.action_jobs.kyc.forEach((action) => {
    for (let i = 0; i < results.length; i += 1) {
      const actionsJSON = results[i];
      if (actionsJSON?.[action]) {
        // display the most recent action, but don't overwrite an action with not applicable
        // basically show the current state or our understanding of this user's kyc
        if (
          actionsJSON[action] === "Not Applicable" &&
          completed[action] &&
          completed[action] !== "Not Applicable"
        ) {
          continue;
        } else {
          completed[action] = actionsJSON[action];
        }
      }
    }
  });
  return { ...completed, ...notApplicable };
};

/**
 * Gets a simplified name for display in spoof and compare reviews
 * @param   {string}  imageUrl  image url returned in the review response
 * @returns  {string}            simplified name for the image
 */
export const getImageLabel = (imageUrl) => {
  if (imageUrl?.includes("Preview")) return "Selfie";
  if (imageUrl?.includes("ID_Photo")) return "ID Authority Photo";
  if (imageUrl?.includes("IDCard_Back")) return "Back of ID Card";
  if (imageUrl?.includes("IDCard")) return "Front of ID Card";

  return "Liveness Check Photo";
};

export const getImageType = (imageURL) => {
  if (imageURL?.includes("Preview")) return "selfie";
  if (imageURL?.includes("ID_Photo")) return "id_authority_photo";
  if (imageURL?.includes("IDCard_Back")) return "id_card_back";
  if (imageURL?.includes("IDCard")) return "id_card";

  return "liveness";
};
