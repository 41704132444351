import React from "react";

function LightIcon({ cssClass }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={cssClass}
    >
      <g>
        <path
          fillRule="evenodd"
          d="M1.94006 6C1.94006 5.197 2.17817 4.41205 2.62429 3.74438C3.07041 3.07672 3.7045 2.55634 4.44636 2.24905C5.18823 1.94175 6.00456 1.86135 6.79212 2.01801C7.57969 2.17467 8.30311 2.56134 8.87091 3.12914C9.43871 3.69695 9.82539 4.42037 9.98205 5.20793C10.1387 5.99549 10.0583 6.81182 9.75101 7.55369C9.44372 8.29556 8.92334 8.92965 8.25567 9.37576C7.58801 9.82188 6.80305 10.06 6.00006 10.06C4.92328 10.06 3.8906 9.63225 3.1292 8.87085C2.36781 8.10945 1.94006 7.07678 1.94006 6Z"
        />
        <path d="M5.80855 10.5657L6.19141 10.5657L6.00283 12L5.80855 10.5657Z" />
        <path d="M3.55138 9.86L3.88281 10.0514L3.00281 11.1971L3.55138 9.86Z" />
        <path d="M1.94871 8.11714L2.14014 8.44857L0.805851 9.00285L1.94871 8.11714Z" />
        <path d="M1.43164 5.80856L1.43164 6.19142L0.000211701 6.00285L1.43164 5.80856Z" />
        <path d="M2.14014 3.55143L1.94871 3.88286L0.802993 3.00286L2.14014 3.55143Z" />
        <path d="M3.88281 1.94857L3.55138 2.14L2.9971 0.805714L3.88281 1.94857Z" />
        <path d="M6.19141 1.43143L5.80855 1.43143L5.99712 -1.95548e-06L6.19141 1.43143Z" />
        <path d="M8.4485 2.14L8.11707 1.94857L8.99707 0.802856L8.4485 2.14Z" />
        <path d="M10.0515 3.88286L9.86005 3.55143L11.1943 2.99714L10.0515 3.88286Z" />
        <path d="M10.5657 6.19144L10.5657 5.80858L12 5.99715L10.5657 6.19144Z" />
        <path d="M9.86012 8.44858L10.0516 8.11715L11.1973 8.99715L9.86012 8.44858Z" />
        <path d="M8.11722 10.0514L8.44864 9.86L9.00293 11.1943L8.11722 10.0514Z" />
      </g>
      <defs />
    </svg>
  );
}

function InvertIcon({ cssClass }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={cssClass}
    >
      <path d="M6 11.5C7.45869 11.5 8.85764 10.9205 9.88909 9.8891C10.9205 8.85765 11.5 7.45869 11.5 6C11.5 4.54131 10.9205 3.14235 9.88909 2.1109C8.85764 1.07946 7.45869 0.5 6 0.5C4.55497 0.543163 3.18096 1.1365 2.15872 2.15874C1.13648 3.18098 0.543164 4.55498 0.5 6C0.5 7.45868 1.07946 8.85765 2.11091 9.8891C3.14236 10.9205 4.54131 11.5 6 11.5ZM6 10.6406C5.08217 10.6406 4.18495 10.3684 3.42181 9.85852C2.65866 9.3486 2.06385 8.62382 1.71261 7.77586C1.36138 6.9279 1.26949 5.99487 1.44854 5.09468C1.6276 4.19449 2.06958 3.36761 2.71858 2.71861C3.36759 2.06961 4.19447 1.6276 5.09466 1.44854C5.99486 1.26948 6.92793 1.3614 7.7759 1.71263C8.62386 2.06387 9.34862 2.65869 9.85854 3.42183C10.3685 4.18498 10.6406 5.08217 10.6406 6C10.6406 7.23077 10.1517 8.41111 9.28142 9.28139C8.41113 10.1517 7.23077 10.6406 6 10.6406Z" />
      <path d="M6 0.5C4.54131 0.5 3.14236 1.07947 2.11091 2.11093C1.07946 3.14238 0.5 4.54131 0.5 6C0.5 7.45869 1.07946 8.85762 2.11091 9.88907C3.14236 10.9205 4.54131 11.5 6 11.5L6 0.5Z" />
    </svg>
  );
}

function ExpandIcon({ cssClass }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      className={cssClass}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55284 5.95323L8.40586 7.8063C8.51642 7.91687 8.60638 7.88056 8.60517 7.72357L8.59029 5.79902C8.58585 5.22485 9.44729 5.23151 9.45173 5.80568L9.50483 9.0776C9.50863 9.31193 9.31957 9.5031 9.08248 9.49996L5.80102 9.45649C5.22686 9.45204 5.2201 8.59068 5.79436 8.59502L7.71878 8.60999C7.87792 8.61123 7.91278 8.52198 7.80149 8.41069L3.45008 4.05917C3.44757 4.05667 3.44511 4.05414 3.44268 4.05159L1.58965 2.19852C1.47909 2.08796 1.38913 2.12427 1.39034 2.28125L1.40523 4.2058C1.40967 4.77998 0.548221 4.77332 0.543781 4.19914L0.495502 0.922407C0.492045 0.687731 0.681107 0.496557 0.917852 0.500047L4.19449 0.548339C4.76865 0.552779 4.77542 1.41414 4.20116 1.40981L2.27674 1.39483C2.11759 1.3936 2.08274 1.48284 2.19403 1.59414L6.54544 5.94565C6.54794 5.94816 6.55041 5.95068 6.55284 5.95323Z"
      />
    </svg>
  );
}

function RotateLeftIcon({ cssClass }) {
  return (
    <svg
      width="32"
      height="25"
      className={cssClass}
      viewBox="0 0 32 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6903 6.8364C18.2053 6.78978 20.7202 7.03838 23.1284 7.73756C24.3935 8.1726 25.6586 8.76302 26.6798 9.52435C27.6858 10.2546 28.7223 11.1402 29.2405 12.3832C29.4996 12.8183 29.7587 13.4397 29.7587 14.0612C29.7587 14.4963 29.7587 15.1333 29.5301 15.7548C29.2862 16.3763 29.0424 16.9822 28.5394 17.6037C28.1736 18.0698 27.6858 18.5515 27.1676 19.0021C26.1769 19.7945 25.0489 20.5713 23.7991 21.0685C22.5493 21.7056 21.1775 22.1872 19.9124 22.3581C17.3974 23.0107 14.6386 23.2127 12.0017 22.9796C10.6147 22.8398 9.34964 22.5601 7.96261 22.1251C6.69752 21.69 5.43243 21.0996 4.28927 20.3694C3.00894 19.608 2.00296 18.7379 1.22562 17.526C0.966504 16.7492 0.57021 16.0034 0.433031 15.3974C0.433031 14.4652 0.417792 13.7194 0.661664 12.9581C0.905537 12.3366 1.27135 11.5597 1.63715 10.9382C2.1249 10.3167 2.62789 9.69526 3.13088 9.22914C4.25879 8.31244 5.49339 7.5045 6.74324 6.86747C5.49339 7.5045 4.24354 8.14153 2.9937 9.0893C2.36877 9.57096 1.86579 10.0371 1.37804 10.7984C0.890297 11.4199 0.646422 12.0258 0.280613 12.9581C0.0367403 13.5796 -0.0851952 14.4807 0.0672253 15.3974C0.204404 16.3141 0.463517 17.0755 0.737873 17.8213C1.24086 18.4272 1.62191 19.1885 2.26208 19.6236C2.78031 20.2451 3.2833 20.851 3.90822 21.1307C5.18856 22.1717 6.4384 22.6223 7.71874 23.1971C9.10576 23.6322 10.4928 24.0672 11.7579 24.3314C14.6539 24.7353 17.4127 24.6887 20.1867 24.3158C21.5738 23.9895 22.9455 23.6477 24.3173 23.0107C25.6891 22.5135 27.0761 21.892 28.3107 20.9598C28.8137 20.3383 29.4234 19.8722 30.0483 19.0798C30.5361 18.4894 31.0391 17.8368 31.4049 16.9356C31.7707 16.0034 32.0146 14.9313 31.9993 13.8592C31.8621 12.7872 31.5878 11.7462 31.2067 10.8295C30.8104 10.0837 30.3075 9.16699 29.6673 8.56103C29.1643 7.95508 28.5241 7.36467 27.884 6.91409C26.6189 5.87309 25.3385 5.28267 23.8296 4.70779C21.1775 3.68233 18.4034 3.2939 15.6294 3.18514L15.5836 0L8.48084 5.32928L15.7208 10.2391L15.6751 6.88301L15.6903 6.8364Z"
      />
    </svg>
  );
}
function RotateRightIcon({ cssClass }) {
  return (
    <svg
      width="32"
      height="24"
      className={cssClass}
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2319 6.69284C13.7171 6.69284 11.2023 6.97688 8.80942 7.7225C7.5444 8.16632 6.29462 8.76992 5.2887 9.53329C4.28278 10.2612 3.27687 11.1488 2.77391 12.356C2.53005 12.7821 2.27094 13.4034 2.27094 13.9893C2.27094 14.4153 2.27094 15.0367 2.5148 15.6225C2.75866 16.2084 3.01776 16.812 3.52072 17.3978C3.88651 17.8416 4.38947 18.3032 4.90767 18.7293C5.91359 19.4927 7.04144 20.2205 8.30646 20.6821C9.55624 21.2679 10.9432 21.7295 12.2082 21.8715C14.723 22.4574 17.4969 22.5994 20.1336 22.3154C21.5206 22.1556 22.7856 21.8715 24.1573 21.41C25.4223 20.9661 26.6721 20.3625 27.8 19.6347C29.065 18.8713 30.0709 18.0014 30.8177 16.812C31.0616 16.0486 31.4426 15.3207 31.5798 14.7171C31.5798 13.8117 31.5798 13.0839 31.3207 12.3383C31.0768 11.7524 30.6958 10.989 30.3148 10.3854C29.8118 9.79959 29.3089 9.19599 28.8059 8.75217C27.678 7.88228 26.4283 7.1189 25.1632 6.5153C26.4283 7.10115 27.678 7.70475 28.9431 8.61014C29.568 9.05396 30.0709 9.51554 30.5739 10.2434C31.0768 10.8293 31.3207 11.4329 31.7017 12.3383C31.9456 12.9241 32.0828 13.8117 31.9456 14.7171C31.8237 15.6225 31.5798 16.3504 31.3207 17.096C30.8177 17.6819 30.4519 18.4452 29.8118 18.8713C29.3089 19.4927 28.8059 20.0785 28.181 20.3626C26.916 21.41 25.6662 21.8538 24.4012 22.4574C23.0142 22.9012 21.6273 23.3628 20.3775 23.6468C17.4969 24.0907 14.723 24.0906 11.9491 23.7889C10.5622 23.5048 9.17521 23.203 7.80351 22.5994C6.41655 22.1556 5.04485 21.552 3.77983 20.6821C3.26162 20.0963 2.65198 19.6347 2.01185 18.8713C1.50889 18.3032 1.00593 17.6819 0.624895 16.812C0.243865 15.9066 0 14.8592 0 13.8117C0.137171 12.7643 0.381033 11.7524 0.746822 10.847C1.12785 10.1191 1.63081 9.21374 2.2557 8.61014C2.75866 8.0243 3.38355 7.4207 4.02369 6.97688C5.27346 5.92946 6.53848 5.34361 8.04736 4.74001C10.6993 3.69259 13.458 3.26653 16.2319 3.10675V-1.05996e-07L23.4105 5.05957L16.2319 9.97712V6.69284Z"
      />
    </svg>
  );
}

function ResetIcon({ cssClass }) {
  return (
    <svg
      width="32"
      className={cssClass}
      height="24"
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.04428 19.376L7.56075 14.9021L10.0772 10.5435L6.67659 12.4691V7.33793C6.81261 3.10614 9.57846 3.37135 9.95253 3.37135H13.7272C13.9766 3.37135 17.0145 3.10614 17.0145 7.33793V14.0027H20.2905V7.45324C20.2905 -0.0878753 14.498 -0.0878741 13.7386 0.0274335H9.96387C9.20439 -0.0994048 3.27595 -0.0994064 3.27595 7.45324V12.4691L0 10.5435L2.51647 14.9021L5.03294 19.376H5.04428ZM26.9671 4.5129L24.4506 9.00989L21.9341 13.4838L25.3348 11.5582V16.551C25.1987 20.9327 22.4329 20.6559 22.0588 20.6559H18.2728C17.8987 20.6559 15.1328 20.9212 14.9968 16.551V10.0246H11.7209V16.551C11.7209 24.1152 17.5246 23.9999 18.2728 23.9999H22.0588C22.6823 23.9999 28.5994 24.1267 28.5994 16.551V11.5582L32 13.4838L29.4835 9.00989L26.9671 4.5129Z"
      />
    </svg>
  );
}

const ZoomInIcon = ({ cssClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={cssClass}
  >
    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
  </svg>
);
const ZoomOutIcon = ({ cssClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className={cssClass}
  >
    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
  </svg>
);
const FlipHorizontalIcon = ({ cssClass }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={100}
    viewBox="0 0 512 512"
    className={cssClass}
  >
    <g>
      <path
        d="M507.83,474.81c-4.73,7.23-12.79,11.59-21.43,11.59H307.2c-14.14,0-25.6-11.46-25.6-25.6V51.2
		c0-12.12,8.5-22.58,20.36-25.06c11.86-2.48,23.84,3.69,28.7,14.8l179.2,409.6C513.32,458.46,512.55,467.58,507.83,474.81z
		 M332.8,173.58V435.2h114.46L332.8,173.58z M12.8,486.4c-4.38,0-8.46-2.24-10.81-5.94c-2.35-3.7-2.64-8.35-0.77-12.31l204.8-435.2
		c2.55-5.42,8.53-8.35,14.37-7.04c5.85,1.31,10.01,6.5,10.01,12.49v435.2c0,7.07-5.73,12.8-12.8,12.8H12.8z"
      />
    </g>
  </svg>
);
const FlipVerticalIcon = ({ cssClass }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    className={cssClass}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M474.81,4.18c7.23,4.73,11.59,12.79,11.59,21.42v179.2c0,14.14-11.46,25.6-25.6,25.6H51.2
		c-12.12,0-22.58-8.5-25.06-20.36c-2.48-11.86,3.69-23.84,14.8-28.7l409.6-179.2C458.46-1.32,467.58-0.55,474.81,4.18z
		 M173.58,179.2H435.2V64.74L173.58,179.2z M486.4,499.2c0,4.38-2.24,8.46-5.94,10.81c-3.7,2.35-8.35,2.64-12.31,0.77l-435.2-204.8
		c-5.42-2.55-8.35-8.52-7.04-14.37c1.31-5.85,6.5-10.01,12.49-10.01h435.2c7.07,0,12.8,5.73,12.8,12.8V499.2z"
      />
    </g>
  </svg>
);

export {
  LightIcon,
  InvertIcon,
  ExpandIcon,
  RotateLeftIcon,
  RotateRightIcon,
  ResetIcon,
  ZoomInIcon,
  ZoomOutIcon,
  FlipHorizontalIcon,
  FlipVerticalIcon,
};
