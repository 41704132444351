import React from "react";
import classNames from "classnames";
import CompareReviewImage from "components/reusable/CompareReview/CompareReviewImage";
import ConfidencePanel, {
  ConfidenceModal,
  ResultsPanel,
} from "components/reusable/CompareReview/Panels";
import ReviewHeader from "../ReviewHeader";

function CompareReview(props) {
  const [sourceGood, setSourceGood] = React.useState(undefined);
  const [targetGood, setTargetGood] = React.useState(undefined);
  const [showConfidence, setShowConfidence] = React.useState(false);
  const [confidence, setConfidence] = React.useState(undefined);
  const [sourceErrors, setSourceErrors] = React.useState(new Set());
  const [targetErrors, setTargetErrors] = React.useState(new Set());
  const [submit, setSubmit] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  React.useEffect(() => {
    evaluateConfidence();
  }, [confidence, sourceGood, targetGood, sourceErrors, targetErrors]);

  const hasFatalErrors = () =>
    sourceErrors.has("image_unusable") ||
    targetErrors.has("image_unusable") ||
    sourceErrors.has("fraud") ||
    targetErrors.has("fraud");

  const evaluateConfidence = () => {
    let _showConfidence;
    let submit = false;
    let left;
    let right = false;
    if (sourceGood === true && targetGood === true) {
      _showConfidence = true;
      if (confidence) {
        submit = true;
      }
    } else {
      if (
        sourceGood === true ||
        (sourceGood === false && sourceErrors.size > 0)
      ) {
        left = true;
      }
      if (
        targetGood === true ||
        (targetGood === false && targetErrors.size > 0)
      ) {
        right = true;
      }
      if (left && right && !hasFatalErrors()) {
        _showConfidence = true;
      }
    }
    if ((left && right && confidence) || hasFatalErrors()) {
      submit = true;
    }
    setShowConfidence(_showConfidence);
    setSubmit(submit);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!submit) return;
    setSubmit(false);
    setSubmitted(true);

    const data = {
      id: props.review.id,
      review: {
        target_errors: Array.from(targetErrors),
        source_errors: Array.from(sourceErrors),
        confidence,
      },
    };

    const { endpoint, submitReview, callback, countdown } = props;
    const response = endpoint ? await endpoint(data) : await submitReview(data);
    if (callback) {
      callback(response, data.id);
    }
    if (countdown) {
      countdown();
    }
  };

  const question =
    parseInt(props.review.job.partner_params.job_type, 10) === 1
      ? "Does this person match this ID Photo?"
      : "Is this the same person?";

  const submitBtnClass = classNames("block-action", {
    "not-active": !submit,
  });

  const targetImages =
    props.review.targets ||
    (props.review.target ? [props.review.target] : undefined);

  return (
    <section className="container__content">
      <ReviewHeader
        title="Compare Review"
        completed={props.completed}
        review={props.review}
        onNextReview={props.next}
      />

      <div className="comparison__content">
        <div className="comparison__content--images">
          <CompareReviewImage
            className="comparison__content__document_item"
            imageUrls={[props.review.source]}
            completed={props.completed}
            isControlGood={sourceGood}
            setIsControlGood={setSourceGood}
            setErrors={setSourceErrors}
            errors={sourceErrors}
            left
            qualityName="sourceGood"
            errorsName="sourceErrors"
            images={
              props.review.images?.source
                ? [props.review.images?.source]
                : undefined
            }
          />

          {targetImages && (
            <CompareReviewImage
              className="target-image"
              imageUrls={targetImages}
              completed={props.completed}
              isControlGood={targetGood}
              setIsControlGood={setTargetGood}
              setErrors={setTargetErrors}
              errors={targetErrors}
              left={false}
              qualityName="targetGood"
              errorsName="targetErrors"
              images={
                props.review.images?.target
                  ? props.review.images?.target
                  : undefined
              }
            />
          )}
        </div>

        <h1
          className={classNames({
            question: sourceErrors.size > 0 || targetErrors.size > 0,
          })}
        >
          {question}
        </h1>
        {submitted && props.counter && (
          <ConfidenceModal
            confidence={confidence}
            counter={props.counter}
            next={props.next}
            setConfidence={setConfidence}
          />
        )}
        {props.review.smile_reference !== undefined && (
          <ResultsPanel
            undo={props.undo}
            smileReference={props.review.smile_reference}
          />
        )}
        <div className="clear-block" />
        {showConfidence && (
          <ConfidencePanel
            confidence={confidence}
            setConfidence={setConfidence}
          />
        )}

        {!props.completed && (
          <div className={submitBtnClass}>
            <button className="btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
export default CompareReview;
