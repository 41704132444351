import React, { useState, useRef } from "react";
import { IMaskInput } from "react-imask";
import Continue from "assets/svgs/continue.svg";
import { CURRENCIES } from "components/auto_pay/constants";
import CurrencyTag from "components/auto_pay/CurrencyTag";
import { createInvoice as createInvoiceApi } from "util/api_util";
import {
  convertCurrency,
  formatCurrency,
  getCurrencySymbol,
} from "util/format_helpers";

export const MIN_AMOUNT_USD = 20.0;

function PayNow({
  autoPay,
  createInvoice = createInvoiceApi,
  designatedCurrency,
  onCreateInvoiceResponse,
}) {
  const exchangeRates = {
    ...autoPay.exchangeRates,
    KSH: autoPay.exchangeRates.KES,
  };
  const minAmount = convertCurrency(
    { state: { exchangeRates, currency: designatedCurrency || "USD" } },
    parseFloat(MIN_AMOUNT_USD),
  );
  const [amount, setAmount] = useState(minAmount);
  const [currency, setCurrency] = useState(designatedCurrency || "USD");
  const formStates = ["idle", "submitting", "error"];
  const [formState, setFormState] = useState(formStates[0]);
  const [error, setError] = useState();

  const currencySymbol = getCurrencySymbol(currency);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const onChange = (changedAmount) => {
    if (parseFloat(changedAmount)) {
      setAmount(parseFloat(changedAmount));
    }
  };

  const handleCurrencyChange = (changedCurrency) => {
    const conversion = {
      state: {
        exchangeRates,
        currency: changedCurrency,
      },
    };
    const convertedAmount = convertCurrency(
      conversion,
      parseFloat(MIN_AMOUNT_USD),
    );

    setAmount(convertedAmount);
    setCurrency(changedCurrency);
  };

  const validateMinAmount = (value) => {
    let validationError = "";
    const convertedMinAmount =
      designatedCurrency === currency
        ? minAmount
        : convertCurrency(
            { state: { exchangeRates, currency } },
            parseFloat(MIN_AMOUNT_USD),
          );

    if (value < convertedMinAmount) {
      const formattedAmount = formatCurrency(
        parseFloat(convertedMinAmount),
        currency,
      );
      validationError = `${formattedAmount} is the minimum funding amount.`;
    }

    return validationError;
  };

  const makePayment = async (event) => {
    event.preventDefault();
    setFormState("submitting");

    const conversion = {
      state: {
        exchangeRates,
        currency,
      },
    };

    try {
      const paymentAmount = parseFloat(amount);
      const validationError = validateMinAmount(paymentAmount);

      if (validationError) {
        throw new Error(validationError);
      }

      if (!autoPay.enabledCurrencies.includes(currency)) {
        throw new Error(
          `Currency (${currency}) is not enabled for this account`,
        );
      }

      const payload = {
        currency,
        partner_id: localStorage.adminPartnerId,
        amount,
      };

      const response = await createInvoice(payload);
      if (response?.checkout_url) {
        window.open(response.checkout_url, "_self");
      } else if (response?.dlocal_sf_key) {
        onCreateInvoiceResponse({ ...response, currency, amount });
      } else if (response?.error) {
        setFormState("error");
        setError(response.error);
      }
    } catch (error) {
      setFormState("error");
      setError(error.message);
    }
  };

  return (
    <div className="stack">
      <h1 className="font-heading">Top up your wallet to get started</h1>
      <form onSubmit={makePayment} className="stack auto-pay">
        <div className="input-group auto-pay">
          <label htmlFor="currency">Select Currency</label>
          <div className="currency-tags-container">
            {autoPay.enabledCurrencies?.map((enabledCurrency) => (
              <CurrencyTag
                data-testid="currency-tag"
                key={enabledCurrency}
                selectedValue={enabledCurrency === currency}
                id={enabledCurrency}
                value={enabledCurrency}
                title={`${enabledCurrency} - ${CURRENCIES[enabledCurrency].name}`}
                onSelect={() => handleCurrencyChange(enabledCurrency)}
              />
            ))}
          </div>
        </div>
        <div className="input-group" style={{ maxInlineSize: "15rem" }}>
          <label htmlFor="amount" id="amount">
            Amount
          </label>
          <IMaskInput
            aria-labelledby="amount"
            name="amount"
            mask={`${currencySymbol} d`}
            radix="."
            data-testid="amount"
            className="text-input"
            blocks={{
              d: {
                mask: Number,
                scale: 2,
                signed: true,
                thousandsSeparator: ",",
                padFractionalZeros: true,
                normalizeZeros: false,
                radix: ".",
              },
            }}
            value={amount.toString()}
            unmask
            ref={ref}
            inputRef={inputRef}
            onAccept={onChange}
          />
        </div>

        {formState === "error" && <p className="color-fail">{error}</p>}

        <div className="input-group" style={{ maxInlineSize: "15rem" }}>
          <p style={{ textAlign: "right" }}>
            <button
              type="submit"
              disabled={formState === "submitting"}
              data-variant="primary"
            >
              <img src={Continue} alt="" />
              <span>Proceed to Payment</span>
            </button>
          </p>
        </div>
      </form>
    </div>
  );
}

export default PayNow;
